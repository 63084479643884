import React, { useEffect, useContext, useState, useRef, useMemo } from 'react';
import Axios from 'axios';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Spin, Row, Col, Select, Divider, Input, Checkbox, Modal } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/DataTable';
import OrderedProductsTable from '../../components/tables/OrderedProductsTable';
import TopSearchBar from '../../components/base/TopSearchBar';
import { SERVER_URL } from '../../config';
import slugify from 'slugify';
import styles from '../data/modal.module.css';

const { TextArea } = Input;

const { Option } = Select;
const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'contacted',
  'gallery',
  'discount',
  'featureImage',
  'follow',
  'views',
  'googleDescription',
  'keywords',
  'content',
  'gallery',
  'categoryPath',
  'fullUrl',
  'url',
  'comments',
  'attributes',
  'featuredAd',
  'featuredAdUntil',
  'user',
  'declaration',
  'featuredProduct',
  'inactiveProduct',
  'superPrice',
  'newProduct',
  'discountWholesale',
  'discountRetail',
  'supplier',
  'status',
  'createdAt',
  'updatedAt',
  'retailPrice',
  'discountRetailPrice',
  'category',
  'discountWholesalePrice',
];

const ACCLASSIF = [
  '01-HO',
  '01-HO-ELA',
  '02-HO',
  '02-HO-ELA',
  '03-HO',
  '03-HO-ELA',
  '04-HO',
  '04-HO-ELA',
  '05-HO',
  '05-HO-ELA',
  '06-BD',
  '06-BD-ELA',
];

const ACCLASSIF_ELA = ['01-HO-ELA', '02-HO-ELA', '03-HO-ELA', '04-HO-ELA', '05-HO-ELA', '06-BD-ELA'];

const HO_EOL = ['01-HO-EOL', '02-HO-EOL', '03-HO-EOL'];

const BannerPage = () => {
  const history = useHistory();
  const location = useLocation();
  const currentuser = useContext(UserContext);
  const { handleSetForAdditionalDiscount, achivedAdditionalDiscount } = useContext(UserContext)
  const userID = currentuser.data.id;
  const [data, setData] = useState();
  const [loggedUser, fetchLoggedUser] = useAxios('', [], currentuser.data.token, 'get');
  const [reload, triggerReload] = useState(false);
  const {id} = useParams()
  const [category, setCategory] = useState(location.pathname.split('/')[2]);
  const [totalWeight, setTotalWeight] = useState(0);
  const [selectedBranch, setSelectedBranch] = useState();
  const [advancePayment, setAdvancePayment] = useState(false);
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [filter, setFilter] = useState('');
  const [working, setWorking] = useState(false);
  const [dataToShow, setDataToShow] = useState([]);
  const [message, setMessage] = useState('');
  const [amount, setAmount] = useState(
    localStorage.getItem(`${userID}_amounts`) ? JSON.parse(localStorage.getItem(`${userID}_amounts`)) : [],
  );

  const [showBackToTop, setShowBackToTop] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [outletValue, setOutletValue] = useState(false);
  const [replacementArticleModal, setReplacementArticleModal] = useState(false);
  const [noProductionModal, setNoProductionModal] = useState(false);
  const [replacementProduct, setReplacementProduct] = useState({});
  const [btnDisabled, setBtnDisabled] = useState(false);

  const timeout = useRef(null);
  let total = localStorage.getItem(`${userID}_totalPrice`)
    ? parseFloat(localStorage.getItem(`${userID}_totalPrice`))
    : 0;
  let orderedProducts = localStorage.getItem(`${userID}_orderedProducts`)
    ? JSON.parse(localStorage.getItem(`${userID}_orderedProducts`))
    : [];
  let accDiscount = localStorage.getItem(`${userID}_discount`)
    ? parseFloat(localStorage.getItem(`${userID}_discount`))
    : 0;
  let discountPercentage = localStorage.getItem(`${userID}_discountPercentage`)
    ? parseFloat(localStorage.getItem(`${userID}_discountPercentage`))
    : 0;

  function handleAdvancePayment(e) {
    const isChecked = e.target.checked;
    localStorage.setItem(`${userID}_AdvancePaymentChecked`, isChecked ? `Yes` : `No`);

    if (isChecked) {
      let orderedProducts = JSON.parse(localStorage.getItem(`${userID}_orderedProducts`));
      orderedProducts = orderedProducts.map((product) => {
        if (product.acClassif && ACCLASSIF_ELA.includes(product.acClassif.trim())) {
          const updatedRebate = 0;
          const discount = updatedRebate / 100;
          const totalPrice = (product.wholesalePrice * (1 - discount) * product.amount).toFixed(2);
          return {
            ...product,
            rebate: updatedRebate,
            totalPrice: totalPrice,
          };
        }

        const foundProduct = data.find((el) => el.code === product.code);
        const updatedRebate = foundProduct.dontShowRebate ? 0 : product.rebate + 5;
        const discount = updatedRebate / 100;
        const totalPrice = (product.wholesalePrice * (1 - discount) * product.amount).toFixed(2);
        return {
          ...product,
          rebate: updatedRebate,
          totalPrice: totalPrice,
        };
      });
      localStorage.setItem(`${userID}_orderedProducts`, JSON.stringify(orderedProducts));

      let total = 0;
      for (const product of orderedProducts) {
        total += parseFloat(product.totalPrice);
      }
      localStorage.setItem(`${userID}_totalPrice`, total.toFixed(2));
    } else {
      let orderedProducts = JSON.parse(localStorage.getItem(`${userID}_orderedProducts`));
      orderedProducts = orderedProducts.map((product) => {
        if (product.acClassif && ACCLASSIF_ELA.includes(product.acClassif.trim())) {
          const updatedRebate = 0;
          const discount = updatedRebate / 100;
          const totalPrice = (product.wholesalePrice * (1 - discount) * product.amount).toFixed(2);
          return {
            ...product,
            rebate: 0,
            totalPrice: totalPrice,
          };
        }
        const foundProduct = data.find((el) => el.code === product.code);
        const updatedRebate = foundProduct.dontShowRebate ? 0 : product.rebate - 5;
        const discount = updatedRebate / 100;
        const totalPrice = (product.wholesalePrice * (1 - discount) * product.amount).toFixed(2);
        return {
          ...product,
          rebate: updatedRebate,
          totalPrice: totalPrice,
        };
      });
      localStorage.setItem(`${userID}_orderedProducts`, JSON.stringify(orderedProducts));

      let total = 0;
      for (const product of orderedProducts) {
        total += parseFloat(product.totalPrice);
      }
      localStorage.setItem(`${userID}_totalPrice`, total.toFixed(2));
    }

    setAdvancePayment(isChecked);
  }

  const [modalOpen, setModalOpen] = useState(false);
  const modalRef = useRef();

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const storedAdvancePayment = localStorage.getItem(`${userID}_AdvancePaymentChecked`);
    if (storedAdvancePayment === 'Yes') {
      setAdvancePayment(true);
    } else {
      setAdvancePayment(false);
    }
  }, []);

  useEffect(() => {
    const tempWeight = orderedProducts.reduce((acc, curr) => {
      return acc + curr.weight * curr.amount;
    }, 0);

    const tempTotalPrice = orderedProducts.reduce((acc, value) => {
      const quantity = value.currentStock >= value.amount ? value.amount : value.currentStock;
      const rebate = value.rebate ? value.rebate : 0;
      return (acc += (+value.wholesalePrice - (+value.wholesalePrice * +rebate) / 100) * quantity);
    }, 0);

    const totalPrice = localStorage.getItem(`${userID}_totalPrice`) || '0';
    setTotalWeight(tempWeight.toFixed(2));

    const totalForSure = orderedProducts && orderedProducts.length > 0 ? +totalPrice : 0

    let leftToDiscount = 25001
    let percentage = 1
    if (orderedProducts && orderedProducts.length > 0) {
      if (+totalPrice > 0 && +totalPrice < 25001) {
        leftToDiscount = 25001 - +totalPrice
        percentage = 1
      } else if (+totalPrice >= 25001 && +totalPrice < 35001) {
        leftToDiscount = 35001 - +totalPrice
        percentage = 2
      } else if (+totalPrice >= 35001 && +totalPrice < 50001) {
        leftToDiscount = 50001 - +totalPrice
        percentage = 3
      } else if (+totalPrice >= 50001 && +totalPrice < 70001) {
        leftToDiscount = 70001 - +totalPrice
        percentage = 4
      } else if (+totalPrice >= 70001 && +totalPrice < 90001) {
        leftToDiscount = 90001 - +totalPrice 
        percentage = 5
      } else if (+totalPrice >= 90001) {
        leftToDiscount = 0
        percentage = 6
      }
    }

    handleSetForAdditionalDiscount(totalForSure, leftToDiscount, percentage)

    if (tempTotalPrice >= 15000 || +totalPrice >= 15000) {
      setDeliveryPrice(0);
    } else if (tempWeight > 0 && tempWeight < 0.5) {
      setDeliveryPrice(166.67);
    } else if (tempWeight >= 0.5 && tempWeight < 1) {
      setDeliveryPrice(183.33);
    } else if (tempWeight >= 1 && tempWeight < 2) {
      setDeliveryPrice(208.33);
    } else if (tempWeight >= 2 && tempWeight < 5) {
      setDeliveryPrice(233.33);
    } else if (tempWeight >= 5 && tempWeight < 10) {
      setDeliveryPrice(325);
    } else if (tempWeight >= 10 && tempWeight < 30) {
      setDeliveryPrice(416.67);
    } else {
      setDeliveryPrice(691.67);
    }
  }, [reload, orderedProducts]);

  const fetchData = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/dashboard-home-front-banners-items/${id}?userId=${currentuser.data.id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setData(response.data);
      setDataToShow(response.data);
    } catch (err) {
      notification.error({
        message: 'Problem sa učitavanjem podataka. Molim Vas da pokušate kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  useEffect(() => {
    fetchData();
    fetchLoggedUser(`${SERVER_URL}/users-company/${currentuser.data.id}`, []);
    return () => {
      setDataToShow([]);
      setData();
    };
  }, [fetchLoggedUser, currentuser.data.id, id]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/data/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Stavka je izbrisana.',
        placement: 'bottomRight',
      });
      history.push('/company');
      history.push('/company/data');
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molim Vas da pokušate kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      history.listen((location) => setCategory(location.pathname.split('/')[2]));
    }
    return () => {
      isMounted = false;
    };
  }, [history]);

  let columnKeys;
  if (data && data.length > 0) {
    const keys = Object.keys(data[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  const show_1_onStock =
    category === 'dandd-jugohemika' || category === 'mimont' || category === 'irkom' || category === 'maxima'
      ? true
      : false;

  if (data && data.length > 0) {
    tableData = data.map((item) => {
      item.onStock = item?.suppliesByProductName?.reduce((acc, curr) => acc + curr.quantity, 0);

      if (item.onStock === 0 && show_1_onStock) {
        item.onStock = 1;
      }
      if (
        item.onStock === 0 &&
        category === 'svi-proizvodi' &&
        item.categories &&
        (item.categories.includes('dandd-jugohemika') ||
          item.categories.includes('mimont') ||
          item.categories.includes('irkom') ||
          item.categories.includes('maxima'))
      ) {
        item.onStock = 1;
      }

      return item;
    });
  }

  const changeBranch = async (e) => {
    setSelectedBranch(loggedUser.data.branches.find((element) => element._id === e));
  };
  useEffect(() => {
    setDataToShow(tableData);
  }, [data]);

  function filterData() {
    let tempData = [...tableData];
    if (outletValue) {
      tempData = tempData.filter((item) => item.acClassif && ACCLASSIF_ELA.includes(item.acClassif.trim()));
    }
    if (filter) {
      const tempFilter = filter.split(' ');
      tempFilter.forEach((filter) => {
        tempData = tempData.filter(
          (item) =>
            item.code.toLowerCase().includes(filter.toLowerCase()) ||
            slugify(item.title.en.toLowerCase()).includes(slugify(filter.toLowerCase())) ||
            (item.supplierCode && item.supplierCode.toLowerCase().includes(filter.toLowerCase())),
        );
      });
    }
    setDataToShow(tempData);
  }

  useEffect(() => {
    if (filter || outletValue) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        filterData();
      }, 500);
    } else {
      setDataToShow(tableData);
    }
    return () => {
      clearTimeout(timeout.current);
    };
  }, [filter, outletValue]);

  useEffect(() => {
    setOutletValue(false);
  }, [window.location.pathname]);

  const addInCart = (propcina, rebate, supplierCode) => {
    const selectedBySupplierCode = tableData.find(
      (item) => supplierCode && item.supplierCode === supplierCode && item.code !== item.supplierCode,
    );
    const selectedProduct = selectedBySupplierCode
      ? selectedBySupplierCode
      : tableData.find((item) => item.code === propcina);

    let updatedRebate = selectedProduct.dontShowRebate
      ? 0
      : rebate && rebate.discount
      ? rebate.discount
      : rebate
      ? rebate
      : 0;
    const advancePaymentChecked = localStorage.getItem(`${userID}_AdvancePaymentChecked`);
    if (advancePaymentChecked === 'Yes' && !selectedProduct.dontShowRebate) {
      updatedRebate += 5;
    }

    orderedProducts.push({
      packageQuantity: selectedProduct.packageQuantity,
      title: selectedProduct.title.en,
      wholesalePrice: parseFloat(selectedProduct.wholesalePrice).toFixed(2),
      code: selectedProduct.code,
      amount: amount.find((item) => item.code === selectedProduct.code).quantity,
      rebate: updatedRebate,
      weight: selectedProduct.weight,
      currentStock: selectedProduct.onStock,
      acClassif: selectedProduct.acClassif,
      supplierCode: selectedProduct.supplierCode,
    });
    localStorage.setItem(`${userID}_orderedProducts`, JSON.stringify(orderedProducts));

    if (selectedProduct.onStock > 0) {
      const basePrice = +selectedProduct.wholesalePrice * selectedProduct.packageQuantity;
      const discountedPrice = basePrice * (1 - updatedRebate / 100);
      total += discountedPrice;
      localStorage.setItem(`${userID}_totalPrice`, total.toString());
    }
    const productWeight = +selectedProduct.weight * selectedProduct.packageQuantity;
    setTotalWeight((prevTotalWeight) => prevTotalWeight + productWeight);

    discountAcclassif();

    triggerReload((prev) => !prev);
    notification.info({
      message: `Proizvod je dodat u korpu.`,
      placement: 'bottomRight',
    });
  };

  const adminRole = sessionStorage.getItem('adminRole');
  const bonus = currentuser.data.noBonus;
  const discountAcclassif = () => {
    const accProducts = orderedProducts.filter((item) => item.acClassif && ACCLASSIF.includes(item.acClassif.trim()));

    let acclassifDiscount = 0;
    let discountPercentage = 0;

    if (accProducts.length > 0 && !adminRole && !bonus) {
      const sum = accProducts.reduce((suma, value) => {
        const quantity = value.currentStock >= value.amount ? value.amount : value.currentStock;
        return (suma += (+value.wholesalePrice - (+value.wholesalePrice * +value.rebate) / 100) * quantity);
      }, 0);

      if (sum >= 15000 && sum <= 30000) {
        acclassifDiscount = sum * 0.01;
        discountPercentage = 1;
      } else if (sum >= 30001 && sum <= 60000) {
        acclassifDiscount = sum * 0.02;
        discountPercentage = 2;
      } else if (sum >= 60001 && sum <= 120000) {
        acclassifDiscount = sum * 0.03;
        discountPercentage = 3;
      } else if (sum > 120000) {
        acclassifDiscount = sum * 0.04;
        discountPercentage = 4;
      }
    }

    localStorage.setItem(`${userID}_discount`, acclassifDiscount.toFixed(2));
    localStorage.setItem(`${userID}_discountPercentage`, discountPercentage);
  };

  const increaseAmount = (propcina, rebate, supplierCode) => {
    const selectedBySupplierCode = tableData.find(
      (item) =>
        supplierCode && item.supplierCode && item.supplierCode === supplierCode && item.code !== item.supplierCode,
    );
    const selectedProduct = selectedBySupplierCode
      ? selectedBySupplierCode
      : tableData.find((item) => item.code === propcina);
    let amountArray = amount;

    if (amountArray.length && amountArray.find((itemcina) => itemcina.code === selectedProduct.code)) {
      amountArray.forEach((item, index) => {
        if (item.supplierCode && supplierCode && item.supplierCode === supplierCode) {
          amountArray[index] = {
            ...item,
            quantity: item.quantity + selectedProduct.packageQuantity,
            currentStock: selectedProduct.onStock,
          };
        } else if (item.code === selectedProduct.code) {
          amountArray[index] = {
            ...item,
            quantity: item.quantity + selectedProduct.packageQuantity,
            currentStock: selectedProduct.onStock,
          };
        }
      });

      orderedProducts.forEach((orderedProduct, index) => {
        const foundProduct = data.find((el) => el.code === orderedProduct.code);
        if (foundProduct && foundProduct.dontShowRebate) {
          orderedProduct.rebate = 0;
        }

        if (orderedProduct.code === selectedProduct.code) {
          const previousQuantity = orderedProduct.amount;
          const increaseQuantity = selectedProduct.packageQuantity;

          orderedProducts[index] = {
            ...orderedProduct,
            amount: previousQuantity + increaseQuantity,
            currentStock: selectedProduct.onStock,
          };

          const acCond =
            selectedProduct.acClassif && ACCLASSIF.includes(selectedProduct.acClassif.trim()) ? true : false;
          if (selectedProduct.onStock > 0) {
            if (!acCond && selectedProduct.onStock > orderedProduct.amount) {
              total +=
                (+orderedProducts[index].wholesalePrice -
                  (+orderedProducts[index].wholesalePrice * +orderedProducts[index].rebate) / 100) *
                increaseQuantity;
              localStorage.setItem(`${userID}_totalPrice`, total.toFixed(2));
            } else if (acCond && selectedProduct.onStock > orderedProduct.amount) {
              total +=
                (+orderedProducts[index].wholesalePrice -
                  (+orderedProducts[index].wholesalePrice * +orderedProducts[index].rebate) / 100) *
                increaseQuantity;
              localStorage.setItem(`${userID}_totalPrice`, total.toFixed(2));
            }
          }

          localStorage.setItem(`${userID}_orderedProducts`, JSON.stringify(orderedProducts));
        }
      });
      discountAcclassif();

      setAmount(amountArray);
      localStorage.setItem(`${userID}_amounts`, JSON.stringify(amountArray));
      triggerReload((prev) => !prev);
    } else {
      amountArray.push({ code: selectedProduct.code, quantity: selectedProduct.packageQuantity, supplierCode });
      localStorage.setItem(`${userID}_amounts`, JSON.stringify(amountArray));
      setAmount(amountArray);
      addInCart(propcina, rebate, supplierCode);
    }
  };

  const decreaseAmount = (propcina, supplierCode) => {
    const selectedBySupplierCode = tableData.find(
      (item) =>
        supplierCode && item.supplierCode && item.supplierCode === supplierCode && item.code !== item.supplierCode,
    );
    const selectedProduct = selectedBySupplierCode
      ? selectedBySupplierCode
      : tableData.find((item) => item.code === propcina);
    let amountArray = amount;
    amountArray.forEach((item, index) => {
      if (item.code === selectedProduct.code && item.quantity !== 0) {
        amountArray[index] = {
          ...item,
          quantity: item.quantity - selectedProduct.packageQuantity,
          currentStock: selectedProduct.onStock,
        };
        localStorage.setItem(`${userID}_amounts`, JSON.stringify(amountArray));
        setAmount(amountArray);
      }
    });
    amountArray = amountArray.filter((amountZero) => amountZero.quantity !== 0);
    localStorage.setItem(`${userID}_amounts`, JSON.stringify(amountArray));
    setAmount(amountArray);
    orderedProducts.forEach((item, index) => {
      const foundProduct = data.find((el) => el.code === item.code);
      if (foundProduct && foundProduct.dontShowRebate) {
        item.rebate = 0;
      }

      if (item.code === selectedProduct.code && item.amount !== 0) {
        const previousQuantity = item.amount;
        const decreaseQuantity = selectedProduct.packageQuantity;

        const acCond = selectedProduct.acClassif && ACCLASSIF.includes(selectedProduct.acClassif.trim()) ? true : false;

        if (selectedProduct.onStock > 0) {
          const decreaseAmount = Math.min(decreaseQuantity, previousQuantity);
          orderedProducts[index] = {
            ...item,
            amount: previousQuantity - decreaseAmount,
            currentStock: selectedProduct.onStock,
          };
          if (!acCond && selectedProduct.onStock >= item.amount) {
            total -= (item.wholesalePrice - (item.wholesalePrice * item.rebate) / 100) * decreaseAmount;
            localStorage.setItem(`${userID}_totalPrice`, total.toFixed(2));
          } else if (acCond && selectedProduct.onStock >= item.amount) {
            total -= (item.wholesalePrice - (item.wholesalePrice * item.rebate) / 100) * decreaseAmount;
            localStorage.setItem(`${userID}_totalPrice`, total.toFixed(2));
          }
        } else {
          orderedProducts[index] = {
            ...item,
            amount: previousQuantity - decreaseQuantity,
            currentStock: selectedProduct.onStock,
          };
        }

        localStorage.setItem(`${userID}_orderedProducts`, JSON.stringify(orderedProducts));
      }
    });
    orderedProducts = orderedProducts.filter((orderedProduct) => orderedProduct.amount !== 0);
    localStorage.setItem(`${userID}_orderedProducts`, JSON.stringify(orderedProducts));
    discountAcclassif();
    triggerReload((prev) => !prev);
  };

  const increaseAmountSecond = (propcina, productId) => {
    const storedProducts = JSON.parse(localStorage.getItem(`${userID}_orderedProducts`));
    const selectedProduct = storedProducts.find((item) => item.code === propcina && item.productId === productId);
    if (!selectedProduct) {
      return;
    }

    let amountArray = amount;
    amountArray.forEach((item, index) => {
      if (item.code === propcina) {
        amountArray[index] = { ...item, quantity: item.quantity + selectedProduct.packageQuantity };
      }
    });

    orderedProducts.forEach((orderedProduct, index) => {
      const foundProduct = data.find((el) => el.code === orderedProduct.code);
      if (foundProduct && foundProduct.dontShowRebate) {
        orderedProduct.rebate = 0;
      }

      if (orderedProduct.code === propcina) {
        const previousQuantity = orderedProduct.amount;
        const increaseQuantity = selectedProduct.packageQuantity;

        orderedProducts[index] = { ...orderedProduct, amount: previousQuantity + increaseQuantity };
      }
    });

    const totalPrice = orderedProducts
      .reduce((accumulator, orderedProduct) => {
        const acCond = orderedProduct.acClassif && ACCLASSIF.includes(orderedProduct.acClassif.trim()) ? true : false;
        const wholesalePrice = +orderedProduct.wholesalePrice;
        const rebate = +orderedProduct.rebate;
        const kolicina =
          orderedProduct.amount <= orderedProduct.currentStock ? orderedProduct.amount : orderedProduct.currentStock;
        const quantity =
          !acCond && orderedProduct.amount <= orderedProduct.currentStock
            ? orderedProduct.amount
            : orderedProduct.currentStock >= orderedProduct.amount
            ? orderedProduct.amount
            : orderedProduct.currentStock;
        if (orderedProduct.currentStock > 0) {
          const productTotal = (wholesalePrice - (wholesalePrice * rebate) / 100) * quantity;
          return accumulator + productTotal;
        }
        return accumulator;
      }, 0)
      .toFixed(2);

    localStorage.setItem(`${userID}_totalPrice`, totalPrice);
    localStorage.setItem(`${userID}_orderedProducts`, JSON.stringify(orderedProducts));
    setAmount(amountArray);
    localStorage.setItem(`${userID}_amounts`, JSON.stringify(amountArray));
    discountAcclassif();
    triggerReload((prev) => !prev);
  };

  const decreaseAmountSecond = (propcina, productId) => {
    const storedProducts = JSON.parse(localStorage.getItem(`${userID}_orderedProducts`));
    const selectedProduct = storedProducts.find((item) => item.code === propcina && item.productId === productId);
    if (!selectedProduct) {
      return;
    }

    let amountArray = amount;
    amountArray.forEach((item, index) => {
      if (item.code === propcina) {
        const newQuantity = item.quantity - selectedProduct.packageQuantity;
        amountArray[index] = {
          ...item,
          quantity: newQuantity >= 0 ? newQuantity : 0,
        };
      }
    });

    orderedProducts.forEach((orderedProduct, index) => {
      const foundProduct = data.find((el) => el.code === orderedProduct.code);
      if (foundProduct && foundProduct.dontShowRebate) {
        orderedProduct.rebate = 0;
      }

      if (orderedProduct.code === propcina) {
        const previousQuantity = orderedProduct.amount;
        const decreaseQuantity = selectedProduct.packageQuantity;

        orderedProducts[index] = {
          ...orderedProduct,
          amount: previousQuantity - decreaseQuantity >= 0 ? previousQuantity - decreaseQuantity : 0,
        };

        if (orderedProducts[index].amount === 0) {
          deleteFromOrderHandler(propcina);
          amountArray = amountArray.filter((item) => item.code !== propcina);
        }
      }
    });

    const totalPrice = orderedProducts
      .reduce((accumulator, orderedProduct) => {
        const acCond = orderedProduct.acClassif && ACCLASSIF.includes(orderedProduct.acClassif.trim()) ? true : false;
        const wholesalePrice = +orderedProduct.wholesalePrice;
        const rebate = +orderedProduct.rebate;
        const quantity =
          !acCond && orderedProduct.amount <= orderedProduct.currentStock
            ? orderedProduct.amount
            : orderedProduct.currentStock >= orderedProduct.amount
            ? orderedProduct.amount
            : orderedProduct.currentStock;
        if (orderedProduct.currentStock > 0) {
          const productTotal = (wholesalePrice - (wholesalePrice * rebate) / 100) * quantity;
          return accumulator + productTotal;
        }
        return accumulator;
      }, 0)
      .toFixed(2);

    localStorage.setItem(`${userID}_totalPrice`, totalPrice);
    localStorage.setItem(`${userID}_orderedProducts`, JSON.stringify(orderedProducts));
    setAmount(amountArray);
    localStorage.setItem(`${userID}_amounts`, JSON.stringify(amountArray));
    discountAcclassif();
    triggerReload((prev) => !prev);
  };

  const deleteFromOrderHandler = (propcina) => {
    let selectedProduct = orderedProducts.find((product) => product.code === propcina);

    const acCond = selectedProduct.acClassif && ACCLASSIF.includes(selectedProduct.acClassif.trim()) ? true : false;

    const quantity = !acCond
      ? selectedProduct.amount
      : selectedProduct.currentStock >= selectedProduct.amount
      ? selectedProduct.amount
      : selectedProduct.currentStock;

    if (selectedProduct.currentStock > 0) {
      const foundProduct = data.find((el) => el.code === selectedProduct.code);
      if (foundProduct && foundProduct.dontShowRebate) {
        selectedProduct.rebate = 0;
      }

      const productTotalPrice =
        (+selectedProduct.wholesalePrice - (+selectedProduct.wholesalePrice * selectedProduct.rebate) / 100) * quantity;
      total -= productTotalPrice;
      localStorage.setItem(`${userID}_totalPrice`, total.toFixed(2));
    }
    const productTotalWeight = +selectedProduct.weight * quantity;
    setTotalWeight((prevTotalWeight) => prevTotalWeight - productTotalWeight.toFixed(2));
    orderedProducts = orderedProducts.filter((orderedProduct) => orderedProduct.code !== propcina);
    localStorage.setItem(`${userID}_orderedProducts`, JSON.stringify(orderedProducts));
    let amountArray = amount.filter((item) => item.code !== propcina);
    localStorage.setItem(`${userID}_amounts`, JSON.stringify(amountArray));
    setAmount(amountArray);
    discountAcclassif();
    triggerReload((prev) => !prev);
  };

  const finishPurchase = async () => {
    try {
      setWorking(true);

      let orders = [];
      let deliveryDetails;
      if (!selectedBranch) {
        deliveryDetails = {
          name: loggedUser.data.companyName,
          address: loggedUser.data.address,
          zip: loggedUser.data.zip,
          city: loggedUser.data.city,
          phone: loggedUser.data.phone1,
        };
      } else {
        deliveryDetails = selectedBranch;
      }
      let orderedProducts = JSON.parse(localStorage.getItem(`${userID}_orderedProducts`));
      for (const orderedProduct of orderedProducts) {
        orders.push({
          name: orderedProduct.title,
          quantity: orderedProduct.amount,
          price: orderedProduct.wholesalePrice,
          code: orderedProduct.code,
          discount: orderedProduct.rebate,
          additionalDiscount: currentuser && currentuser.data && !currentuser.data.noBonus && !adminRole ? achivedAdditionalDiscount : 0,
          packageQuantity: orderedProduct.packageQuantity,
          weight: orderedProduct.weight,
        });
      }
      const totalPriceForDB = achivedAdditionalDiscount && currentuser && currentuser.data && !currentuser.data.noBonus && !adminRole ? ((total - (total * (achivedAdditionalDiscount / 100))) + deliveryPrice - accDiscount + ((total - (total * (achivedAdditionalDiscount / 100))) + deliveryPrice) * 0.2).toFixed(2)  : (total + deliveryPrice - accDiscount + (total + deliveryPrice) * 0.2).toFixed(2)

      const totalPdvForDB = achivedAdditionalDiscount && currentuser && currentuser.data && !currentuser.data.noBonus && !adminRole ? ((total - (total * (achivedAdditionalDiscount / 100))) + deliveryPrice) * 0.2 : (total + deliveryPrice) * 0.2
      
      const totalProductsForDB = achivedAdditionalDiscount && currentuser && currentuser.data && !currentuser.data.noBonus && !adminRole ? (total - (total * (achivedAdditionalDiscount / 100))) : total

      let data = {
        order: orders,
        user: loggedUser.data,
        deliveryDetails: deliveryDetails,
        deliveryPrice: deliveryPrice,
        totalPrice: totalPriceForDB,
        advancePayment,
        message,
        accDiscount,
        discountPercentage,
        pdv: totalPdvForDB,
        totalProducts: totalProductsForDB,
      };
      await Axios.post(`${SERVER_URL}/orders`, data, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Kupovina je obavljena',
        placement: 'bottomRight',
      });
      localStorage.removeItem(`${userID}_orderedProducts`);
      localStorage.removeItem(`${userID}_amounts`);
      localStorage.removeItem(`${userID}_totalPrice`);
      localStorage.removeItem(`${userID}_discount`);
      localStorage.removeItem(`${userID}_AdvancePaymentChecked`);
      setWorking(false);
      history.push('/company');
      history.push(`/company/${category}`);
      window.scrollTo(0, 0);
    } catch (error) {
      setWorking(false);
      console.log('error', error);
      notification.error({
        message: 'Došlo je do greške prilikom kupovine',
        placement: 'bottomRight',
        duration: 3,
      });
    }
  };

  let nf = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

   useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const modalArticle = async (record) => {
    if (record.acClassif && HO_EOL.includes(record.acClassif.trim())) {
      const zamenskiArtikal = tableData.find(
        (item) =>
          item.acClassif &&
          HO_EOL.includes(item.acClassif.trim()) &&
          item.zamenskiArtikal &&
          item.zamenskiArtikal === record.zamenskiArtikal,
      );

      if (zamenskiArtikal) {
        setReplacementArticleModal(true);
        try {
          const response = await Axios.get(`${SERVER_URL}/data-by-code?code=${zamenskiArtikal.zamenskiArtikal}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });
          setBtnDisabled(false);
          setReplacementProduct(response.data);
        } catch (error) {
          console.log(error);
          notification.error({
            message: 'Zamenski artikal za ovaj proizvod vise ne postoji.',
            placement: 'bottomRight',
          });
          setBtnDisabled(true);
        }
      }
    }
  };
  
  const modalNoArticle = async (record) => {
    if (record.acClassif && HO_EOL.includes(record.acClassif.trim())) {
      const zamenskiArtikal = tableData.find(
        (item) =>
          item.acClassif &&
          HO_EOL.includes(item.acClassif.trim()) &&
          item.zamenskiArtikal &&
          item.zamenskiArtikal === record.zamenskiArtikal,
      );

      if (!zamenskiArtikal) {
        setNoProductionModal(true);
      }
    }
  };

  return (
    <div className='table'>
      <TopSearchBar
        outletValue={outletValue}
        setOutletValue={setOutletValue}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setFilter={setFilter}
      />
      <Spin spinning={working} tip='Učitavanje...'>
        <div className='product-table' style={{ textAlign: 'center' }}>
          {!data && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
          {dataToShow.length > 0 && (
            <Table
              modalNoArticle={modalNoArticle}
              btnDisabled={btnDisabled}
              replacementProduct={replacementProduct}
              replacementArticleModal={replacementArticleModal}
              setReplacementArticleModal={setReplacementArticleModal}
              noProductionModal={noProductionModal}
              setNoProductionModal={setNoProductionModal}
              modalArticle={modalArticle}
              HO_EOL={HO_EOL}
              ACCLASSIF_ELA={ACCLASSIF_ELA}
              data={dataToShow}
              deleteHandler={deleteDataHandler}
              columnKeys={columnKeys}
              rebate={loggedUser.data}
              title='Banner'
              editPath='/company/edit-data/'
              increaseAmount={increaseAmount}
              decreaseAmount={decreaseAmount}
              amount={amount}
              addInCart={addInCart}
              currentuser={currentuser}
              show_1_onStock={show_1_onStock}
              userID={userID}
              achivedAdditionalDiscount={achivedAdditionalDiscount}
              adminRole={adminRole}
            />
          )}
          {data && !data.length && <h2>NEMA PODATAKA</h2>}
        </div>
        <div className='secondSection'>
          {orderedProducts.length !== 0 && (
            <>
              <div className='order-container' style={{ marginTop: '20px' }}>
                <div className='order-title-container'>
                  <i className='navMenuIcon ri-list-check' style={{ fontSize: '18px' }}></i>
                  <p className='order-title-text'>PORUDŽBINA</p>
                </div>
                <div className='product-table custom-ordered-products-table'>
                  <OrderedProductsTable
                    data={orderedProducts}
                    deleteFromOrderHandler={deleteFromOrderHandler}
                    columnKeys={columnKeys}
                    title='OrderedProducts'
                    increaseAmount={increaseAmountSecond}
                    decreaseAmount={decreaseAmountSecond}
                    amount={amount}
                    achivedAdditionalDiscount={achivedAdditionalDiscount}
                    currentuser={currentuser}
                    adminRole={adminRole}
                  />
                </div>
              </div>

              <Row className='delivery-container' style={{ marginTop: '10px', justifyContent: 'space-between' }}>
                <Col span={12}>
                  <div className='order-title-container' style={{ paddingTop: '0px' }}>
                    <i className='navMenuIcon ri-truck-line' style={{ fontSize: '20px' }}></i>
                    <p className='order-title-text'>CENA DOSTAVE</p>
                  </div>
                  <p style={{ textTransform: 'initial', fontWeight: '400', fontSize: '15px', lineHeight: '1.4' }}>
                    Za sve porudžbine vrednosti manje od <span style={{ color: '#f95e01' }}>15.000 RSD</span> bez pdv-a
                    <br />
                    obračunaju se troškovi transporta po{' '}
                    <span style={{ color: '#f95e01', cursor: 'pointer' }} onClick={openModal}>
                      cenovniku BEX Kurirske službe{' '}
                      <span
                        style={{
                          cursor: 'pointer',
                          position: 'relative',
                          top: '4px',
                          marginLeft: '4px',
                        }}
                      >
                        <span
                          style={{
                            position: 'absolute',
                            top: '-2px',
                            right: '-15px',
                            width: '16px',
                            height: '16px',
                            background: '#f95e00',
                            color: '#fff',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '11px',
                            fontWeight: 'bold',
                            padding: '8px',
                          }}
                        >
                          i
                        </span>
                      </span>
                    </span>
                  </p>
                  {modalOpen && (
                    <div className={`${styles.modal} ${modalOpen ? styles.open : ''}`}>
                      <div className={styles.blur}></div>
                      <div className={styles['modal-content']} ref={modalRef}>
                        <span className={styles.close} onClick={closeModal}>
                          &times;
                        </span>
                        <p style={{ fontWeight: '400', textTransform: 'initial' }}>
                          Usled izazovnih vremena, paketna isporuka biće izvršena u što kraćem roku{' '}
                          <span style={{ color: '#F95E01', display: 'block' }}>Kurirskom službom BEX.</span>
                          Sva roba ima bruto težinu i po njoj se određuje zbir ukupne težine pošiljke.
                        </p>
                        <br />
                        <p style={{ fontWeight: '400', textTransform: 'initial' }}>
                          Za sve pošiljke koje su ispod <span style={{ color: '#F95E01' }}>15.000 dinara</span> bez
                          pdv-a biće obračunati troškovi transporta po sledećim cenama:
                        </p>
                        <br />

                        <table>
                          <tbody className='delivererTable'>
                            <tr>
                              <td>Paket od</td>
                              <td style={{ color: '#F95E01', paddingLeft: '5px', paddingRight: '10px' }}>
                                0,35 do 0,50 kg
                              </td>
                              <td>- 166,67 RSD</td>
                            </tr>
                            <tr>
                              <td>Paket od</td>
                              <td style={{ color: '#F95E01', paddingLeft: '5px', paddingRight: '10px' }}>
                                0,50 do 1 kg
                              </td>
                              <td>- 183,33 RSD</td>
                            </tr>
                            <tr>
                              <td>Paket od</td>
                              <td style={{ color: '#F95E01', paddingLeft: '5px', paddingRight: '10px' }}>1 do 2 kg</td>
                              <td>- 208,33 RSD</td>
                            </tr>
                            <tr>
                              <td>Paket od</td>
                              <td style={{ color: '#F95E01', paddingLeft: '5px', paddingRight: '10px' }}>2 do 5 kg</td>
                              <td>- 233,33 RSD</td>
                            </tr>
                            <tr>
                              <td>Paket od</td>
                              <td style={{ color: '#F95E01', paddingLeft: '5px', paddingRight: '10px' }}>5 do 10 kg</td>
                              <td>- 325,00 RSD</td>
                            </tr>
                            <tr>
                              <td>Paket od</td>
                              <td style={{ color: '#F95E01', paddingLeft: '5px', paddingRight: '10px' }}>
                                10 do 30 kg
                              </td>
                              <td>- 416,67 RSD</td>
                            </tr>
                            <tr>
                              <td>Paket od</td>
                              <td style={{ color: '#F95E01', paddingLeft: '5px', paddingRight: '10px' }}>
                                30 do 50 kg
                              </td>
                              <td>- 691,67 RSD</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  <Divider type='horizontal' />
                  <p style={{ textTransform: 'initial', fontSize: '15px', lineHeight: '1.4' }}>
                    Težina porudžbine je <span style={{ color: '#f95e01' }}>{totalWeight} kg</span>
                  </p>
                  <p style={{ textTransform: 'initial', fontSize: '15px', lineHeight: '1.4' }}>
                    Trošak dostave je <span style={{ color: '#f95e01' }}>{deliveryPrice} RSD</span>
                  </p>
                </Col>
                <Col className='columnCompany' span={7}>
                  {loggedUser.data.branches && loggedUser.data.branches.length > 0 ? (
                    <Row>
                      <Col span={6} style={{ margin: 'auto' }}>
                        <p>Ogranak</p>
                      </Col>
                      <Col span={18}>
                        <Select onChange={(e) => changeBranch(e)} style={{ width: '100%' }}>
                          <Option value={null}>
                            <i style={{ textDecoration: 'italic' }}>None</i>
                          </Option>
                          {loggedUser.data &&
                            loggedUser.data.branches &&
                            loggedUser.data.branches.length &&
                            loggedUser?.data?.branches?.map((item) => <Option value={item._id}>{item.name}</Option>)}
                        </Select>
                      </Col>
                    </Row>
                  ) : null}
                  <Row>
                    <Col span={14}>OSNOVICA</Col>
                    <Col span={10} style={{ textAlign: 'end' }}>
                      {achivedAdditionalDiscount && currentuser && currentuser.data && !currentuser.data.noBonus && !adminRole ? nf.format((total - (total * (achivedAdditionalDiscount / 100))).toFixed(2)) : nf.format(total.toFixed(2))} RSD
                    </Col>
                  </Row>
                  <Row>
                    <Col span={14}>TROŠKOVI DOSTAVE</Col>
                    <Col span={10} style={{ textAlign: 'end' }}>
                      {nf.format(parseFloat(deliveryPrice).toFixed(2))} RSD
                    </Col>
                  </Row>
                  {accDiscount ? (
                    <Row>
                      <Col span={14} style={{ color: 'red' }}>
                        NAGRADA ZA SAMOPORUČIVANJE
                      </Col>
                      <Col span={10} style={{ textAlign: 'end', color: 'red' }}>
                        -{nf.format(accDiscount)} RSD
                      </Col>
                    </Row>
                  ) : null}
                  <Divider type='horizontal' />
                  <Row>
                    <Col span={14}>UKUPNO BEZ PDV-a</Col>
                    <Col span={10} style={{ textAlign: 'end' }}>
                      {achivedAdditionalDiscount && currentuser && currentuser.data && !currentuser.data.noBonus && !adminRole ? nf.format(((total - (total * (achivedAdditionalDiscount / 100)) + deliveryPrice - accDiscount)).toFixed(2)) : nf.format((total + deliveryPrice - accDiscount).toFixed(2))} RSD
                    </Col>
                  </Row>
                  <Row>
                    <Col span={14}>PDV</Col>
                    <Col span={10} style={{ textAlign: 'end' }}>
                      {achivedAdditionalDiscount && currentuser && currentuser.data && !currentuser.data.noBonus && !adminRole ? nf.format((
                        (
                          (total - (total * (achivedAdditionalDiscount / 100))) + deliveryPrice
                        ) * 0.2
                      ).toFixed(2)) : nf.format(((total + deliveryPrice) * 0.2).toFixed(2))} RSD
                    </Col>
                  </Row>
                  <Divider type='horizontal' />
                  <Row className='bolded'>
                    <Col span={14}>ZA PLAĆANJE</Col>
                    <Col span={10} style={{ textAlign: 'end' }}>
                      {achivedAdditionalDiscount && currentuser && currentuser.data && !currentuser.data.noBonus && !adminRole ? nf.format((
                        (total - (total * (achivedAdditionalDiscount / 100))) + deliveryPrice - accDiscount + ((total - (total * (achivedAdditionalDiscount / 100))) + deliveryPrice) * 0.2
                      ).toFixed(2)) : nf.format((total + deliveryPrice - accDiscount + (total + deliveryPrice) * 0.2).toFixed(2))} RSD
                    </Col>
                  </Row>
                  <TextArea
                    style={{ borderRadius: '3px' }}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder='Napomena'
                    autoSize={{
                      minRows: 3,
                      maxRows: 5,
                    }}
                  />
                  {loggedUser.data &&
                  !loggedUser.data.noAvans &&
                  loggedUser.data.supplierDiscount &&
                  loggedUser.data.supplierDiscount.length > 0 &&
                  loggedUser.data.supplierDiscount[0].currency > 10 ? (
                    <div className='advance_payment_checkbox'>
                      <Checkbox
                        onChange={handleAdvancePayment}
                        checked={advancePayment || localStorage.getItem(`${userID}_AdvancePaymentChecked`) === 'Yes'}
                      >
                        AVANSNA UPLATA
                      </Checkbox>
                    </div>
                  ) : null}

                  <div className='button-div' style={{ display: 'flex', justifyContent: 'center', marginTop: '18px' }}>
                    <button
                      className='b2b-primary'
                      style={{ width: '100%' }}
                      onClick={finishPurchase}
                      disabled={working}
                    >
                      ZAVRŠI PORUDŽBINU
                    </button>
                  </div>
                  <br />
                </Col>
              </Row>
            </>
          )}
        </div>
        {showBackToTop && (
          <button
            className='back-to-top-button'
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            style={{ backgroundColor: '#f95e01' }}
          >
            &#9650;
          </button>
        )}
      </Spin>
      <Modal open={isModalOpen} footer={false} onCancel={() => setIsModalOpen(false)}>
        <span className='outlet-title'>OUTLET!</span>
        <span className='outlet-line'></span>
        <span className='outlet-desc'>Artikli na trajnom snizenju, bez dodatnog rabata.</span>
      </Modal>
    </div>
  );
}

export default BannerPage