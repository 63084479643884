import React, { createContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { message } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from './config';
import Layout from './components/base/Layout';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import './App.scss';

export const UserContext = createContext(null);

const handleLogin = async (user) => {
  try {
    const userResponse = await Axios.post(
      `${SERVER_URL}/login`,
      { email: user.email.toLowerCase(), password: user.password },
      { withCredentials: false },
    );
    if (userResponse.data.message === 'Auth successful') {
      sessionStorage.setItem('user', JSON.stringify(userResponse.data));
      window.location.reload();
    }
  } catch (err) {
    console.log('\n Login error = ', err.response.data.message);
    return message.warning(err.response.data.message, 3);
  }
};
const handleRedirection = async () => {
  sessionStorage.removeItem('user');
  try {
    const userRedirect = await Axios.get(`${SERVER_URL}/log-in-admin?limit=1`);
    const redirect = userRedirect.data;
    const userResponse = await Axios.post(`${SERVER_URL}/login?admin=true`, redirect.items[0].userId, {
      withCredentials: false,
    });
    if (userResponse.data.message === 'Auth successful') {
      sessionStorage.setItem('user', JSON.stringify(userResponse.data));
    }

    if (redirect.items[0].createdBy) {
      sessionStorage.setItem('adminRole', redirect.items[0].createdBy.role);
    }
    window.location.reload();
  } catch (error) {
    console.log('error', error);
  }
};

const nf = new Intl.NumberFormat('en-us', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

const App = () => {
  const [isLoggedIn, setLoggedIn] = useState(true);
  const [language, setLanguage] = useState('en');
  const [topBar, setTopBar] = useState('');
  const [totalDebt, setTotalDebt] = useState(null)
  const [totalAmount, setTotalAmount] = useState(0)
  const [addMoreToDiscount, setAddMoreToDiscount] = useState(20001)
  const [additionalDiscount, setAdditionalDiscount] = useState(1)
  const [achivedAdditionalDiscount, setAchivedAdditionalDiscount] = useState(0)

  let user = sessionStorage.getItem('user');
  if (user) user = JSON.parse(user);
  let previousPath = document.referrer;
  let currentPath = window.location.pathname;

  useEffect(() => {
    if (
      (process.env.NODE_ENV === 'development' &&
        (previousPath === 'http://localhost:3000/' ||
          previousPath === 'https://himtexcompanyb2b-admin-staging.concordsofttest.com/')) ||
      (process.env.NODE_ENV === 'production' &&
        previousPath === 'https://himtexcompanyb2b-admin-staging.concordsofttest.com/')
    ) {
      handleRedirection();
    }
  }, []);

  useEffect(() => {
    if (user && new Date() > new Date(user.expires)) {
      console.log('Session expired!');
      setLoggedIn(false);
    } else if (user === null) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }, [setLoggedIn, user]);

  const handleSetTotalDebt = (val) => {
    setTotalDebt(val)
  }

  const handleSetForAdditionalDiscount = (val,leftToDiscount,percentage) => {
    setTotalAmount(val)
    setAdditionalDiscount(percentage)
    setAchivedAdditionalDiscount(percentage - 1)
    setAddMoreToDiscount((leftToDiscount).toFixed(2))
  }

  return (
    <UserContext.Provider value={{ isLoggedIn, handleLogin, data: user, language, setLanguage, handleSetTotalDebt, totalDebt, handleSetForAdditionalDiscount, totalAmount, addMoreToDiscount, nf, additionalDiscount, achivedAdditionalDiscount }}>
      <Router>
        {!isLoggedIn && currentPath.includes('password-reset') && <ResetPassword />}
        {isLoggedIn && !currentPath.includes('password-reset') && <Layout topBar={topBar} setTopBar={setTopBar} />}
        {!isLoggedIn && !currentPath.includes('password-reset') && <Login />}
      </Router>
    </UserContext.Provider>
  );
};

export default App;
