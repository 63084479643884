import React, { useContext, useEffect, useRef, useState } from 'react';
import { Input, Checkbox } from 'antd';
import { IoIosInformationCircle } from "react-icons/io";
import { UserContext } from '../../App';

const TopSearchBar = (props) => {

  const infoRef = useRef()
  const iconRef = useRef()
  const adminRole = sessionStorage.getItem('adminRole')
  const currentuser = useContext(UserContext);

  const { addMoreToDiscount, nf, additionalDiscount } = useContext(UserContext)
  const [additionalDiscountInfo, setAdditionalDiscountInfo] = useState(false)
  
  const handleToggleInfo = () => {
    setAdditionalDiscountInfo(prev => !prev)
  }

  const handleCloseInfo = (e) => {
    if (infoRef && infoRef.current && !infoRef.current.contains(e.target) && iconRef && iconRef.current && !iconRef.current.contains(e.target)) {
      setAdditionalDiscountInfo(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleCloseInfo)

    return () => {
      document.removeEventListener('mousedown', handleCloseInfo)
    }
  },[])

  return (
    <div className='top-search-bar-container'>
      <div className='top-bar-menu-item-box orang' style={{ position: 'relative', paddingRight: '10px' }}>
        <i
          className='ri-search-line'
          style={{ color: '#9f9f9f', position: 'absolute', top: 22, right: 20, zIndex: '7' }}
        ></i>

        <Input className='search-input'
          onChange={(e) => props.setFilter(e.target.value)}
          placeholder='UPIŠITE NAZIV, DEO NAZIVA ILI ŠIFRU ARTIKLA'
          style={{ width: '335px', paddingLeft: '40px' }}
        />
      </div>
      <div className='top-bar-menu-item-box orange sm-paddings'>
        <Checkbox />
        BRZI MENI
        <i className='ri-information-fill' style={{ marginLeft: '10px' }}></i>
      </div>
      <div className='top-bar-menu-item-box orange sm-paddings'>
        <Checkbox checked={props.outletValue} onChange={({ target }) => {
          props.setIsModalOpen(!props.isModalOpen);
          props.setOutletValue(!props.outletValue);
          if (target.checked === false) {
            props.setIsModalOpen(false);
          }
        }} />
        OUTLET
        <i className='ri-information-fill' style={{ marginLeft: '10px' }}></i>
      </div>

      {currentuser && currentuser.data && !currentuser.data.noBonus && !adminRole && (
        <>
          <div className='additional_discount_container'>
            <p className='info'>DODAJTE JOŠ <span style={{color: '#fb5700'}}>{nf.format(addMoreToDiscount)}</span> ZA</p>
          </div>

          <div className='additional_discount_sum_container'>
            <p>{additionalDiscount === 6 ? 'Maximalni rabat dostignut' : `+ ${additionalDiscount} RABATA`}</p>
            <div ref={iconRef} onClick={(e) => handleToggleInfo(e)}>
              <IoIosInformationCircle style={{color: 'white', fontSize:'20px', cursor:'pointer'}}/>
            </div>

            <div ref={infoRef} className={`total_debt_info_container ${additionalDiscountInfo ? 'active' : ''}`}>
              <div className='debt_title'>
                <span>DODATNI RABAT NA IZNOS</span>
              </div>
              
              <div className='table_grid'>
                <span className={additionalDiscount === 2 ? 'active' : ''}>25.001 - 35.000</span>
                <span className={additionalDiscount === 2 ? 'active' : ''}>+1%</span>
                <span className={additionalDiscount === 3 ? 'active' : ''}>35.001 - 50.000</span>
                <span className={additionalDiscount === 3 ? 'active' : ''}>+2%</span>
                <span className={additionalDiscount === 4 ? 'active' : ''}>50.001 - 70.000</span>
                <span className={additionalDiscount === 4 ? 'active' : ''}>+3%</span>
                <span className={additionalDiscount === 5 ? 'active' : ''}>70.001 - 90.000</span>
                <span className={additionalDiscount === 5 ? 'active' : ''}>+4%</span>
                <span className={additionalDiscount === 6 ? 'active' : ''}>90.001...</span>
                <span className={additionalDiscount === 6 ? 'active' : ''}>+5%</span>
              </div>
            </div>
          </div>
        </>
      )}

    </div>
  );
};

export default TopSearchBar;
