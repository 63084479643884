import React from 'react';
import { Table, Row, Col, Input } from 'antd';
import Icon, { SearchOutlined } from '@ant-design/icons';

const dateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};

function OrderOneForm({
  data,
  language,
  token,
}) {

  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Pretraga za ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 220, marginBottom: 8, display: 'block' }}
          />
          <button
            className='b2b-primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            // size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Traži
          </button>
          <button
            className='b2b-secondary'
            onClick={() => handleReset(clearFilters)}
            // size='small'
            style={{ width: 90 }}
          >
            Reset
          </button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#fff', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex)
        ? !dataIndex.includes('category')
          ? record[dataIndex[0]][dataIndex[1]]
          : record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
        : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = (clearFilters) => {
    clearFilters();
  };

  let adresa;
  if (data && data.deliveryDetails && data.deliveryDetails.address) {
    adresa = `${data.user.address}, ${data.user.zip} ${data.user.city}`;
  } else if (data.user && data.user.place && data.user.place.name) {
    adresa = `${data.user.street.name} ${data.user.streetNumber}, ${data.user.zip} ${data.user.place.name}`;
  } else if (data.user.legalData && data.user.legalData.address) {
    adresa = `${data.user.legalData.address}, ${data.user.legalData.zip} ${data.user.legalData.city}`;
  } else {
    adresa = '';
  }

  let adresaIsporuke;
  if (data && data.deliveryDetails && data.deliveryDetails.address) {
    adresaIsporuke = `${data.deliveryDetails.address}, ${data.deliveryDetails.zip} ${data.deliveryDetails.city}`;
  } else if (data.user && data.user.deliveryAddressSame && data.user.place && data.user.place.name) {
    adresaIsporuke = `${data.user.street.name} ${data.user.streetNumber}, ${data.user.zip} ${data.user.place.name}`;
  } else if (data.user && !data.user.deliveryAddressSame && data.user.place && data.user.place.name) {
    adresaIsporuke = `${data.user.streetDelivery.name} ${data.user.streetNumberDelivery}, ${data.user.zipDelivery} ${data.user.placeDelivery.name}`;
  } else if (data.user.legalData && data.user.legalData.address) {
    adresaIsporuke = `${data.user.legalData.address}, ${data.user.legalData.zip} ${data.user.legalData.city}`;
  } else {
    adresaIsporuke = '';
  }

  let telefon;
  if (data.user.phone1) {
    telefon = data.user.phone1;
  } else if (data.user.phone && data.user.phone.length > 0) {
    telefon = data.user.phone[0];
  }

  const order_details = {
    kupac: {
      ime: data.user.legalType === 'corporate' ? data.user.companyName : data.user.fullName ? data.user.fullName : `${data.user.firstName} ${data.user.lastName}`,
      adresa: adresa,
      adresaIsporuke: adresaIsporuke,
      email: data.user.email,
      telefon: telefon,
      vremePorudzbine: new Date(data.createdAt).toLocaleDateString('sr-Latn-RS', dateOptions),
      napomena: data.message
    },
    total: {
      ukupno: data.order.reduce((acc, curr) => acc + curr.price * curr.quantity * ((100 - curr.discount) / 100) * ((100 - (curr.additionalDiscount ? curr.additionalDiscount : 0)) / 100), 0),
      prevoz: data.deliveryPrice,
      pdv: (data.order.reduce((acc, curr) => acc + curr.price * curr.quantity * ((100 - curr.discount) / 100) * ((100 - (curr.additionalDiscount ? curr.additionalDiscount : 0)) / 100), 0) + +data.deliveryPrice) * 0.2,
      zaUplatu: data.order.reduce((acc, curr) => acc + curr.price * curr.quantity * ((100 - curr.discount) / 100) * ((100 - (curr.additionalDiscount ? curr.additionalDiscount : 0)) / 100), 0) + +data.deliveryPrice - (data && data.accDiscount ? data.accDiscount : 0),
      avansnaUplata: data.advancedPayment ? "DA" : "NE",
    }
  };

  console.log(data);

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      ...getColumnSearchProps('code'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      ...getColumnSearchProps('quantity'),
    },
    {
      title: 'JM',
      dataIndex: 'kom',
      key: 'kom',
      render: (value, record) => (
        <span>KOM</span>
      ),
    },
    {
      title: 'Pakovanje',
      dataIndex: 'packageQuantity',
      key: 'packageQuantity',
      render: (value, record) => (
        <span>{value ? value : '1'}</span>
      ),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      ...getColumnSearchProps('price'),
    },
    {
      title: 'Rabat',
      dataIndex: 'discount',
      key: 'discount',
      render: (value, record) => {
        return (
          <span>{record.discount}% {record.additionalDiscount ? (
              <span style={{color:'#f84804', fontWeight:'600'}}>+{record.additionalDiscount}%</span>
            ) : null}
          </span>
        )
      },
    },
    {
      title: 'Vrednost',
      dataIndex: 'price',
      key: 'price',
      render: (value, record) => {
        let totalPrice = record.price * record.quantity * ((100 - record.discount) / 100)
        if (record.additionalDiscount) {
          totalPrice = totalPrice * ((100 - record.additionalDiscount) / 100)
        }
        return (
          <span>{(totalPrice).toFixed(2)}</span>
        )
      },
    },
  ];

  let nf = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  return (
    <div>
      <div style={{ padding: '0 2rem', marginTop: '-30px' }}>
        <div className='orderHeader'>
          <i className="ri-file-list-2-line"></i>
          <span className='orderHeaderSpan'>PORUDŽBINA #{data.orderNumber}</span>
        </div>
        <Row type='flex' gutter={16}>
          <Col xs={24} md={24}>
            <div className='panel-body-wrapper' style={{ marginBottom: '30px' }}>
              <div>
                <Table
                  className='singleOrder'
                  dataSource={data.order}
                  columns={columns}
                  pagination={{
                    defaultPageSize: 20,
                    position: 'bottom',
                    showSizeChanger: false,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    hideOnSinglePage: true,
                  }}
                  rowKey='_id'
                  bordered
                />
              </div>
            </div>
          </Col>
          <Col className='orderCol' xs={24} md={24}>
            <div className='singleOrderLeftSide'>
              <div>
                <div className='left-row'>
                  <span className='row-span'>Kupac:</span>
                  <span className='row-span'>{order_details.kupac.ime}</span>
                </div>
                <div className='left-row'>
                  <span className='row-span'>Adresa:</span>
                  <span className='row-span'>VOJVOĐANSKA 97, 21242 Budisava</span>
                </div>
                <div className='left-row'>
                  <span className='row-span orangeSpan'>Adresa Isporuke:</span>
                  <span className='row-span orangeSpan'>{order_details.kupac.adresaIsporuke}</span>
                </div>
                <div className='left-row'>
                  <span className='row-span'>Email:</span>
                  <span className='row-span'>{order_details.kupac.email}</span>
                </div>
                <div className='left-row'>
                  <span className='row-span'>Telefon:</span>
                  <span className='row-span'>{order_details.kupac.telefon}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className='row-span'>Napomena</span>
                  <Input.TextArea readOnly value={data.message} id='message' className='ant-input padding' rows={10} />
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <div className='info-top'>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className='row-span'>Vreme porudzbine:</span>
                    <span className='row-span'>{order_details.kupac.vremePorudzbine}</span>
                  </div>
                  <div>
                    <span className='row-span'>Naručeno sa: {data.orderedVia ? data.orderedVia : ''}</span>
                  </div>
                </div>
                <div className='reference'>
                  <span className='row-span'>Referenca</span>
                  <Input value={data.reference} readOnly id='reference' className='ant-input readonly' type='text' />
                </div>
                <div className='info-bottom'>
                  <span className='row-span'>Fakture</span>
                  <div>
                    <Input className='input-readonly' placeholder='# Faktura 1' readOnly />
                    <Input className='input-readonly' placeholder='# Faktura 2' readOnly />
                    <Input className='input-readonly' placeholder='# Faktura 3' readOnly />
                  </div>
                </div>
              </div>
            </div>
            <div className='singleOrderRightSide'>
              <div className='ind-row'>
                <span>Ukupno:</span>
                <span>{nf.format(order_details.total.ukupno.toFixed(2))}</span>
              </div>
              <div className='ind-row'>
                <span>Prevoz:</span>
                <span>{nf.format(order_details.total.prevoz)}</span>
              </div>
              <div className='ind-row'>
                <span>Nagrada za samoporučivanje:</span>
                <span>{data.accDiscount ? nf.format(data.accDiscount) : '0.00'}</span>
              </div>
              <div className='ind-row'>
                <span>PDV:</span>
                <span>{nf.format(order_details.total.pdv.toFixed(2))}</span>
              </div>
              <div className='ind-row'>
                <span>Za uplatu:</span>
                <span>{nf.format((order_details.total.zaUplatu + order_details.total.pdv).toFixed(2))}</span>
              </div>
              <div className='ind-row'>
                <span>Avansna uplata:</span>
                <span>{order_details.total.avansnaUplata}</span>
              </div>
            </div>
          </Col>
        </Row>
      </div >
    </div>
  );
}

export default OrderOneForm;
